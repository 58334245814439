<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 v-if="job.name" class="font-18 mx-auto">
        {{ $filters.truncate(job.name, 17, "..") }}
      </h1>
      <router-link
        :to="{ name: 'Dashboard' }"
        @click="menuOpen('menu-main')"
        data-menu="menu-main"
        class="page-title-icon rounded-s ms-3"
      ></router-link>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content">
      <h1 class="font-15 ms-3">{{ uniqueId }}</h1>
      <UserApply
        v-for="apply in listApply"
        :key="apply.id"
        :apply="apply"
        :job="job"
      />

      <EmptyState
        v-if="listApply.length === 0"
        :message1="'belum ada yang melamar'"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import UserApply from "@/components/employer/UserApply";
import EmptyState from "@/components/EmptyState";
export default {
  name: "ApplyJobList",
  components: {
    Header,
    Footer,
    UserApply,
    EmptyState,
  },
  data() {
    return {
      job: {},
      listApply: [],
      apiUrl: "/api/v1/employer/job/apply/",
      uniqueId: "",
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    var urlParams = this.$route.params.job_id;
    var job_id = urlParams.split("#")[0];
    console.log("urlParams", urlParams);
    this.uniqueId = "#" + urlParams.split("#")[1];
    console.log(urlParams, this.uniqueId);

    this.getApplyList(job_id);
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    getApplyList(job_id) {
      this.apiUrl = this.apiUrl + job_id + "/";
      axios
        .get(this.apiUrl)
        .then((response) => {
          console.log(response);
          this.listApply = response.data[0].apply_job;
          console.log("list apply", this.listApply);
          this.job = {
            id: response.data[0].id,
            name: response.data[0].title,
            logoUrl: axios.defaults.baseURL + response.data[0].company.get_logo,
          };
        })
        .catch((error) => {});
    },
  },
};
</script>
