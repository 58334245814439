<template>
  <div
    class="card card-style"
    @click="
      this.$router.push({
        name: 'DetailApplyJob',
        params: { job_id: job.id, user_id: apply.user.id },
      })
    "
  >
    <div class="content">
      <div class="d-flex">
        <div class="align-self-center">
          <img
            :src="photoUrl"
            class="rounded-m me-3"
            style="height: 84px; width: 84px; object-fit: cover"
          />
        </div>
        <div class="me-auto align-self-center">
          <h2 class="font-15 color-highlight line-height-s mt-1 mb-n1">
            {{ apply.user.first_name }}
          </h2>
          <p class="mb-0 font-11 mt-2 line-height-s">
            {{ apply.applied_at }}
          </p>
        </div>
        <div class="align-self-center">
          <a
            href="#"
            class="
              btn btn-s
              font-600
              rounded-s
              gradient-highlight
              mt-1
              float-end
            "
            >Lihat</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserApply",
  props: {
    apply: Object,
    job: Object,
  },
  data() {
    return {
      photoUrl: this.apply.user.get_photo,
    };
  },
  methods: {},
};
</script>
